import { PokemonClient, NamedAPIResourceList, Pokemon } from "pokenode-ts";
import { pokemonListOptions } from './index';
import { useState, useEffect, useCallback } from "react";

export const usePokemonList = (offset: pokemonListOptions["offset"], limit: pokemonListOptions["limit"]) => {
    const [data, setData] = useState<NamedAPIResourceList>();
    const [error, setError] = useState<Error | null>(null);
    
    const pokemonList = useCallback((offset: pokemonListOptions["offset"], limit: pokemonListOptions["limit"]) => {
        const api = new PokemonClient();
            api
            .listPokemons(offset, limit)
            .then((data) => {
                setData(data);
            })
            .catch((error) => {
                setError(error);
            });
        }, []);

        useEffect(() => {
            pokemonList(offset, limit);
        }, [pokemonList, offset, limit]);
        
    return { data, error, pokemonList };
};


export const usePokemonFullList = (offset: pokemonListOptions["offset"], limit: pokemonListOptions["limit"]) => {
    const [fullData, setFullData] = useState<NamedAPIResourceList>();
    const [error, setError] = useState<Error | null>(null);

    const getPokemonFullList = useCallback((offset: pokemonListOptions["offset"], limit: pokemonListOptions["limit"]) => {
        const api = new PokemonClient();
            api
            .listPokemons(offset, limit)
            .then((data) => {
                setFullData(data);
            })
            .catch((error) => {
                setError(error);
            });
        }, []);

        useEffect(() => {
            getPokemonFullList(offset, limit);
        }, [getPokemonFullList, limit, offset]);
        
    return { error, fullData, getPokemonFullList };
};

export const usePokemonDetail = (name: string) => {
    const [detailData, setDetailData] = useState<Pokemon>();
    const [errorDetail, setErrorDetail] = useState<Error | null>(null);
    
    const getPokemon = useCallback((name: string) => {
        const api = new PokemonClient();
            api
            .getPokemonByName(name)
            .then((data) => {
                setDetailData(data);
            })
            .catch((error) => {
                setErrorDetail(error);
            });
        }, []);

        useEffect(() => {
            getPokemon(name);
        }, [getPokemon, name]);
        
    return { detailData, errorDetail, getPokemon };
};