import { useState } from 'react';
import { 
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';
import { TablePagination } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { NamedAPIResourceList, NamedAPIResource } from 'pokenode-ts';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export interface triggerEvent extends NamedAPIResourceList {
  changePage: (value: number) => void;
  changeRows: (value: number) => void;
  paginationDisable: boolean;
  pokemonDetail: (value: string) => void;
  myFavList: any;
}

const TableList = function BasicTable({
  count,
  results,
  myFavList,
  changePage,
  changeRows,
  paginationDisable,
  pokemonDetail,
}: triggerEvent) {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowPage, setRowPage] = useState(100);
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
      changePage(newPage);
    }

    const handleChangeRowPage = (rowsPerPage: any) => {
      setRowPage(rowsPerPage.target.value);
      changeRows(rowsPerPage.target.value);
    }

    const handlePokemonDetail = (name: string) => {
      pokemonDetail(name);
    }

    const favExists = (val: string) => {
      return myFavList.some( (myFavList: { [x: string]: string; }) => myFavList['name'] === val );
    };
  return (
    <>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="Pokemon List" stickyHeader={true}>
        <TableHead>
          <TableRow>
          <TableCell align="left">No.</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((row: NamedAPIResource, i: number) => (
            <TableRow key={i+1} hover={true} onClick={() => handlePokemonDetail(row.name)}>
              <TableCell align="left">{i+ page * rowPage +1}</TableCell>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">
                {favExists(row.name) ? 
                  <FavoriteIcon sx={{color: '#ffcb06'}} />
                  : <FavoriteIcon sx={{color: '#eee'}} />
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {!paginationDisable &&
    <TablePagination
      rowsPerPageOptions={[10, 25, 50, 100]}
      component="div"
      count={count}
      rowsPerPage={rowPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowPage}
    />
    }
    </>
  );
}

export default TableList;
