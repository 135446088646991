import React, { useEffect, useState } from 'react';
import './PokemonList.css';
import { usePokemonList, usePokemonFullList, usePokemonDetail } from '../../api/pokemon.service';
import TableList from '../Table/Table';
import PokemonTabs from '../Tabs/Tabs';
import PokemonDialog from '../Dialog/Dialog';
import { favPoke } from '../../api/types';
import { NamedAPIResource } from 'pokenode-ts';

const PokemonList = (): React.ReactElement => {
  const [ offset, setOffset ] = useState({value: 0});
  const [ limit, setLimit ] = useState({value: 100});
  const [ pokemonName, setPokemonName ] = useState("bulbasaur");
  const { data, error, pokemonList } = usePokemonList(offset.value, limit.value);
  const { detailData, errorDetail, getPokemon } = usePokemonDetail(pokemonName);
  const { fullData } = usePokemonFullList(offset.value, data?.count);
  const [ pokemonDataList, setPokemonDataList ] = useState(data?.results);
  const [ someFilterDisabled, setSomeFilterDisabled ] = useState(false);
  const [ sortOrder, setSortOrder ] = useState(0);
  const [ dialogOpen, setDialogOpen ] = useState(false);
  const [ addFavorite, setAddFavorite ] = useState<favPoke>();
  const [ favoriteList, setFavoriteList ] = useState<NamedAPIResource[]>([]);

  if (error || errorDetail) {
    console.error("fetching Pokemon error :", error, errorDetail);
  }

  useEffect(() => {
    const favList = JSON.parse(localStorage.getItem('results') || '[]');
    if (favList) {
      setFavoriteList(favList);
      localStorage.setItem('results', JSON.stringify(favList));
    }
  }, []);

  useEffect(() => {
    setPokemonDataList(data?.results);
  }, [pokemonList, offset, data, limit]);

  const onSearchPokemon = (searchString: any) => {
    if (searchString.length > 0) {
      let searchResult = fullData?.results.filter(i => i["name"].match(searchString));
      setPokemonDataList(searchResult);
      setSomeFilterDisabled(true);
    } else {
      setPokemonDataList(data?.results);
      setSomeFilterDisabled(false);
    }
  };

  const onChangePage = (offSetValue: number) => {
    offSetValue = offSetValue * limit.value;
    setOffset({ ...offset, value: offSetValue });
    pokemonList(offSetValue, limit.value);
  };

  const onChangeRows = (rowValue: number) => {
    setLimit({ ...limit, value: rowValue });
    pokemonList(offset.value, rowValue);
  };

  const onSortOrder = () => {
    if (data) {
      const sortedData = [...data?.results];
      sortedData.sort((a, b) => {
        return sortOrder ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
      });
      setPokemonDataList(sortedData);
      setSortOrder(sortOrder ? 0 : 1);
    }
  }

  const handlePokemonDialog = (value: string) => {
    getPokemon(value);
    setPokemonName(value);
    setDialogOpen(true);
  }

  const handleDialogClose = (value: boolean) => {
    setDialogOpen(value);
  }

  const favExists = (val: string) => {
    return favoriteList.some( (favoriteList) => favoriteList['name'] === val );
  };

  const handleAddFavorite = () => {
    const getSingleFav: NamedAPIResource = {
      name: detailData?.name ? detailData?.name : '',
      url: detailData?.sprites.front_default ? detailData?.sprites.front_default : '',
    };
    setAddFavorite(getSingleFav);
    const favList = JSON.parse(localStorage.getItem('results') || '[]');
    if (favList && favExists(getSingleFav.name)) return;
    if (favList) {
      favList.push(getSingleFav);
      setFavoriteList(favList);
      localStorage.setItem('results', JSON.stringify(favList));
    } else {
      localStorage.setItem('results', JSON.stringify(getSingleFav));
    }
  }

  const showFavList = () => {
    setPokemonDataList(favoriteList);
    setSomeFilterDisabled(true);
  }

  const handleonTabChange = () => {
    setPokemonDataList(data?.results);
    setSomeFilterDisabled(false);
  }

  return (
      <div className='tableWrapper'>
        {data && pokemonDataList &&
        <>
          <PokemonTabs
            onSearch={onSearchPokemon}
            onSort={onSortOrder}
            isSortDisable={someFilterDisabled}
            favorite={addFavorite}
            showFavList={showFavList}
            tabChange={handleonTabChange}
          ></PokemonTabs>
          <TableList
            results={pokemonDataList}
            count={data.count}
            myFavList={favoriteList}
            previous={data.previous}
            next={data.next}
            changePage={onChangePage}
            changeRows={onChangeRows}
            paginationDisable={someFilterDisabled}
            pokemonDetail={handlePokemonDialog}
          ></TableList>
          {detailData &&
          <PokemonDialog
            isOpen={dialogOpen}
            dialogClose={handleDialogClose}
            data={detailData}
            addFavorite={handleAddFavorite}
          ></PokemonDialog>
          }
        </>
      }
      </div>
  );
};

export default PokemonList;