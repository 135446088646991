import * as React from 'react';
import {
    Button,
    styled,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    Avatar
} from '@mui/material';
import { 
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineOppositeContent,
    TimelineDot
} from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import './Dialog.css';
import { DialogTitleProps, triggerEvent } from '../../api/types';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const PokemonDialog = function CustomizedDialogs({
    data,
    isOpen,
    dialogClose,
    addFavorite
}: triggerEvent) {

  const handleClose = () => {
    dialogClose(false);
  };

  const handleAddFav = () => {
    addFavorite();
    dialogClose(false);
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            <>
            <Avatar
                alt={data.name}
                src={data.sprites.front_default ? data.sprites.front_default : ''}
                sx={{
                    position: 'absolute',
                    left: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[200],
                    width: 50,
                    height: 50,
                    border: 1,
                  }}
            />
            <label className='avatarLabel'>{data.name}</label>
            </>
        </BootstrapDialogTitle>
        <DialogContent dividers>
            <Timeline position="alternate">
            <TimelineItem>
                <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
                >
                Height {data.height}
                </TimelineOppositeContent>
                <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot>
                    <Avatar
                    alt={data.name}
                    src={data.sprites.front_default ? data.sprites.front_default : ''}/>
                </TimelineDot>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                    {data.name}
                </Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
                >
                <Typography>Weight {data.weight}</Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot>
                    <Avatar
                        alt={data.name}
                        src={data.sprites.back_default ? data.sprites.back_default : ''}/>
                </TimelineDot>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                    Moves
                </Typography>
                <Typography>{data.moves.length}</Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                variant="body2"
                color="text.secondary"
                >
                Stats {data.stats.length}
                </TimelineOppositeContent>
                <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">
                    <Avatar
                        alt={data.name}
                        src={data.sprites.front_shiny ? data.sprites.front_shiny : ''}/>
                </TimelineDot>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                    Base Experience
                </Typography>
                <Typography>{data.base_experience}</Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary" variant="outlined">
                    <Avatar
                        alt={data.name}
                        src={data.sprites.back_shiny ? data.sprites.back_shiny : ''}/>
                </TimelineDot>
                <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                    Abilities
                </Typography>
                <Typography>{data.abilities.length}</Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineSeparator>
                <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                <TimelineDot color="secondary">
                    <Avatar
                        alt={data.name}
                        src={data.sprites.front_default ? data.sprites.front_default : ''}/>
                </TimelineDot>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                    Game Indices
                </Typography>
                <Typography>{data.game_indices.length}</Typography>
                </TimelineContent>
            </TimelineItem>
            </Timeline>
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={handleAddFav}>
                Add to favorite
            </Button>
            <Button autoFocus onClick={handleClose}>
                Cancel
            </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default PokemonDialog;