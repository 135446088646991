import './App.css';
import Header from './pages/Header/Header';
import PokemonList from './pages/PokemonList/PokemonList';

function App() {
  return (
    <div className="App">
      <Header></Header>
      <PokemonList></PokemonList>
    </div>
  );
}

export default App;
