import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, TextField, Avatar } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import { triggerEventTabs } from '../../api/types';

const useStyles = makeStyles({
    root: {
      flex: 1,
    },
    rightAlign: {
        marginLeft: 'auto',
     },
    tab: {
      minWidth: 650,
      border: '1px solid #eee',
      padding: 10,
    },
    labelIcon: {
      paddingLeft: '10px',
    },
  });

const PokemonTabs = function IconTabs({
    onSearch,
    onSort,
    showFavList,
    tabChange,
    isSortDisable,
    favorite,
  }: triggerEventTabs) {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    tabChange();
  };

  const handleOnChange = (event: any) => {
    onSearch(event.target.value);
  }

  return (
    <Tabs value={value} aria-label="Pokemon list header" onChange={handleChange} className={classes.tab}>
      <Tab icon={
        <>
            <SearchIcon /> <TextField id="outlined-basic" label="Search Pokemon" variant="outlined" onChange={handleOnChange} />
        </>
        } className={classes.root} />
      <Tab icon={<FavoriteIcon />} label="My Favorites" onClick={showFavList} className={classes.root} />
      <Tab icon={<SortByAlphaIcon />} label="Sort" className={classes.root} onClick={onSort} disabled={isSortDisable}  />
      {favorite?.url && 
      <Tab icon={
        <Avatar
          alt={'Favorite'}
          src={favorite.url}
          sx={{
            color: (theme) => theme.palette.grey[200],
            width: 50,
            height: 50,
            border: 1,
          }}/>} className={classes.labelIcon} disabled={true} label={favorite.name} iconPosition='start' />
      }
    </Tabs>
  );
}

export default PokemonTabs;