import logo from '../../resource/images/logo.svg';
import './Header.css';

const Header = (): React.ReactElement => {
  return (
      <header className="header">
        <img src={logo} className="logo" alt="Pokemon logo" />
      </header>
  );
};

export default Header;